import { Component, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { ImportExcelPageModule } from './import-excel/import-excel.module';
import { isTokenGuard } from './token.guard';
import { AutoformEditPageModule } from './autoform-edit/autoform-edit.module';
import { InitialStateFieldsPageModule } from './initial-state-fields/initial-state-fields.module';
import { DashboardPage } from './dashboard/dashboard.page';
import { DashboardPageModule } from './dashboard/dashboard.module';
import { PanelPage } from './panel/panel.page';
import { PanelPageModule } from './panel/panel.module';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppLayoutComponent,
          children: [
            {
              path: 'dashboard',
              loadChildren: () => DashboardPageModule,
              canActivate: [isTokenGuard],
            },
            {
              path: '',
              loadChildren: () => DashboardPageModule,
              canActivate: [isTokenGuard],
            },
            {
              path: 'form-objective/:id',
              loadChildren: () =>
                import('./form-objetive/form-objetive.module').then(
                  (m) => m.FormObjetivePageModule
                ),
            },
            {
              path: 'form-initial-state/:id',
              loadChildren: () =>
                import('./form-initial-state/form-initial-state.module').then(
                  (m) => m.FormInitialStatePageModule
                ),
            },
            {
              path: 'initial-state/form/:id',
              loadChildren: () => InitialStateFieldsPageModule,
            },
            {
              path: 'initial-state/:id',
              loadChildren: () => AutoformEditPageModule,
            },
            {
              path: 'test-page',
              loadChildren: () =>
                import('./test-page/test-page.module').then(
                  (m) => m.TestPagePageModule
                ),
              canActivate: [isTokenGuard],
            },
            {
              path: 'form',
              loadChildren: () =>
                import('./form/form.module').then((m) => m.FormPageModule),
              canActivate: [isTokenGuard],
            },
            {
              path: 'indicadores',
              loadChildren: () =>
                import('./menu-indicator/menu-indicator.module').then(
                  (m) => m.MenuIndicatorPageModule
                ),
              canActivate: [isTokenGuard], // Load the module for your new page
            },
            {
              path: 'test',
              loadChildren: () =>
                import('./test-page/test-page.module').then(
                  (m) => m.TestPagePageModule
                ),
              canActivate: [isTokenGuard], // Load the module for your new page
            },
            {
              path: 'base-page/:item',
              loadChildren: () =>
                import('./base-page/base-page.module').then(
                  (m) => m.BasePagePageModule
                ),
              canActivate: [isTokenGuard],
            },
            {
              path: 'base-indicator/:indicator.name/:id',
              loadChildren: () =>
                import('./base-indicator/base-indicator.module').then(
                  (m) => m.BaseIndicatorPageModule
                ),
            },
            {
              path: 'base-qualitative/:indicator.name/:id',
              loadChildren: () =>
                import('./base-qualitative/base-qualitative.module').then(
                  (m) => m.BaseQualitativePageModule
                ),
            },
            {
              path: 'base-test/:indicator.name',
              loadChildren: () =>
                import('./base-test/base-test.module').then(
                  (m) => m.BaseTestPageModule
                ),
            },
            {
              path: 'panel',
              loadChildren: () => PanelPageModule,
              canActivate: [isTokenGuard],
            },
          ],
          canActivate: [isTokenGuard],
        },
        { path: '**', redirectTo: 'notfound' },
      ],
      { preloadingStrategy: PreloadAllModules, useHash: true }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
