<ion-grid class="full-grid">
  <ion-row>
    <div class="header"></div>
  </ion-row>
  <ion-row style="margin-top: -6rem !important;">
    <div class="word-container">
      <button pButton (click)="handleFilterModal()" [outlined]="true" icon="pi pi-file-word" label="Descargar informe Word" [loading]="loadingWord"></button>
    </div>
    <ion-col size-xs="12" size-sm="12" size-md="12" class="margin-col-start margin-col-end">
      <p-card header="Resumen indicadores">

        <button
              class="p-button font-bold mb-3"
              style="opacity: 0.6;"
              [disabled]="true"
            >
              Descargar XBRL
            </button>
        @if(!model){
        <ion-col size-xs="12" size-sm="12" size-md="6" class="margin-col-end">
          <div class="spinner-container">
            <ion-spinner name="dots"></ion-spinner>
          </div>
        </ion-col>
        } @else {
        <p-accordion *ngFor="let item of model['items']; let i = index">
          <!-- Temporal -->
          <p-accordionTab>
            <ng-template pTemplate="header">
              <i class=" {{ item.icon }}">
                <span class="ml-2" style="font-family: 'Inter var', sans-serif !important;">{{ item.label }}</span>
              </i>
            </ng-template>
            <p-accordion *ngFor="let item of item['items']; let i = index">
              <p-accordionTab>
                <ng-template pTemplate="header">
                  <i class=" {{ item.icon }}">
                    <span class="ml-2"
                      style="font-weight: 400 !important;font-family: 'Inter var', sans-serif !important;">{{ item.label
                      }}</span>
                  </i>
                </ng-template>
                <p-accordion *ngFor="let item of item['items']; let i = index">
                  <p-accordionTab>
                    <ng-template pTemplate="header">
                      <i class=" {{ item.icon }}">
                        <span class="ml-2"
                          style="font-weight: 400 !important;font-family: 'Inter var', sans-serif !important;">{{
                          item.label }}</span>
                      </i>
                    </ng-template>
                    <p *ngFor="let indicator of item.indicatorNames; let i = index" class="list-item">
                      <i class="pi pi-sign-in click" (click)="redirect(indicator)">
                        <span (click)="redirect(indicator)" class="ml-2"
                          style="font-weight: 400 !important;font-family: 'Inter var', sans-serif !important;">{{
                          indicator.name }}</span>
                      </i>
                      @if(indicator.isValidated === 'No validado' || indicator.isValidated === 'No encontrado') {
                        <p-chip class="custom-chip-danger" icon="pi pi-check" pTooltip="Indicador no validado"
                        tooltipPosition="top" label="No validado"></p-chip>
                      } 
                      @if (indicator.isValidated === 'Validado') {
                        <p-chip class="custom-chip-success" icon="pi pi-check" pTooltip="Indicador validado"
                            tooltipPosition="top" label="Validado"></p-chip>
                      }
                      @if (indicator.isEvaluated === 'Si') {
                        <div class="traffic-light">
                          <div class="light grey"></div>
                          <div class="light grey"></div>
                          <div class="light green"></div>
                        </div>
                      }
                      @if (indicator.isEvaluated === 'No') {
                        <div class="traffic-light">
                          <div class="light red"></div>
                          <div class="light grey"></div>
                          <div class="light grey"></div>
                        </div>
                      }
                    </p>
                  </p-accordionTab>
                </p-accordion>
              </p-accordionTab>
            </p-accordion>
          </p-accordionTab>
        </p-accordion>
        }
      </p-card>
    </ion-col>
  </ion-row>
</ion-grid>

<p-dialog
  header="Filtro de evidencias"
  [(visible)]="dialogFilter"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '80vw' }"
>  

@if(loadingModal){
  <div class="spinner-container">
    <ion-spinner name="dots"> </ion-spinner>
  </div>
}

@if(!loadingModal){
  <div class="title-container">
    <span>Elija si lo desea un rango de fechas para filtrar las evidencias de los indicadores</span>
  </div>
  <ion-grid class="p-5">
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <label>Rango de fechas</label>
        <div class="mt-1">
          <p-calendar 
          class="calendar-form"
          appendTo="body"
          [(ngModel)]="rangeDates" 
          selectionMode="range" 
          dateFormat="dd/mm/yy"
          [readonlyInput]="true" />
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
}
<ng-template pTemplate="footer">
  @if(rangeDates.length > 0){
    <p-button label="Limpiar filtro" severity="danger"  (click)="rangeDates = []"> </p-button>
  }
  <p-button label="Descargar" [loading]="loadingWord" (click)="downloadIndicatorsWord()"> </p-button>
</ng-template>
</p-dialog>