import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private UserApiUrl = environment.usersApiURL;
  private userInfo: any;

  constructor(private http: HttpClient) {}

  getInfoUser() {
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http
      .get(this.UserApiUrl + '/api/users/actual', { headers })
      .pipe(
        tap((userInfo) => {
          this.userInfo = userInfo;
        })
      );
  }

  getUserInfo(): any {
    return this.userInfo;
  }

  // Método para obtener datos del users api
  getData(relativeUrl: string): Observable<any> {
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    const url = this.UserApiUrl + relativeUrl;
    return this.http.get(url, { headers });
  }  


}
