import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { ServerService } from '../server.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];
  test = 'clase-test';
  menuData: any;
  companyId: any;

  constructor(
    public layoutService: LayoutService,
    private serverService: ServerService
  ) {}

  ngOnInit() {
    this.companyId = sessionStorage.getItem('companyId');
    this.recuperarDatosMenu();
  }

  /**
   * Función para parsear la respuesta JSON que devuelve el servidor.
   * @param {Object} response - Respuesta devuelta por el servidor.
   * @returns {Array} - Array con los elementos del menú.
   */
  parsearRespuesta(response) {
    return JSON.parse(response.data.json_menu);
  }

  /**
   * Función para agregar el routerLink a los elementos del menú que no tienen hijos
   * @param {Array} items - Array de elementos del menú.
   */
  /* agregarRouterLink1(items: any[]) {
        // Iterar sobre todos los elementos del menú
        items.forEach(item => {
            // Si el elemento del menú tiene hijos, iterar sobre ellos para agregar el routerLink
            if (item.items && item.items.length > 0) {
                this.agregarRouterLink(item.items);
            } else {
                // Asignar el routerLink solo al último elemento
                if (!item.items) {
                    item.routerLink = ['base-page', JSON.stringify(item)]; // Asigna la ruta adecuada
                }
            }

            // Eliminar la propiedad 'items' solo si está vacía
            if (item.items && item.items.length === 0) {
                item.routerLink = ['base-page', JSON.stringify(item)]; // Asigna la ruta adecuada para eliminar items
                delete item.items;
            }
        });
    } */

  agregarRouterLink(items) {
    const dataToStore: any[] = []; // Array para almacenar los datos relevantes
    items.items.forEach((item) => {
      // Si el elemento del menú tiene hijos, iterar sobre ellos para agregar el routerLink
      if (item.items && item.items.length > 0) {
        this.agregarRouterLink(item);
      } else {
        item.routerLink = ['base-page', JSON.stringify(item)];

        item.command = (event) => this.saveLocalStorage(item.label, item.icon);

        delete item.items; // Eliminar la propiedad 'items' si no tiene hijos
      }

      // Eliminar la propiedad 'items' solo si está vacía
      if (item.items && item.items.length === 0) {
        delete item.items;
      }
    });
  }

  saveLocalStorage(label: string, icon: string) {
    let dataToStore: any[] = [];
    dataToStore.push({ label: label, icon: icon });
    localStorage.setItem('LabelIcon', JSON.stringify(dataToStore));
  }

  /**
   * Agrega elementos adicionales al array de datos del menú.
   */
  agregarElementosAdicionales(noData?: boolean) {
    if (noData) {
      this.model['items'] = [];
    }

    const dashboard = {
      label: 'Dashboard',
      icon: 'pi pi-home',
      routerLink: ['/dashboard'],
    };

    const apartadoEmpresa = {
      label: 'Mercadona',
      icon: 'pi pi-building',
      items: [
        {
          label: 'Carta del director',
          icon: null,
        },
        {
          label: 'Introducción',
          icon: null,
        },
        {
          label: 'Conoce a Mercadona',
          icon: null,
          items: [
            {
              label: 'Conoce a Mercadona',
              icon: null,
            },
            {
              label: 'Principales cifras',
              icon: null,
            },
            {
              label:
                'Estructura societaria y de gobierno, propiedad y forma juridica',
              icon: null,
            },
            {
              label: 'Entorno economico',
              icon: null,
            },
            {
              label: 'Información general sobre el sistema de gobernanza',
              icon: null,
            },
            {
              label: 'Modelo de negocio',
              icon: null,
            },
          ],
        },
        {
          label: 'Compromisos y prácticas responsables de Mercadona',
          icon: null,
          items: [
            {
              label: 'Compromiso con la gobernanza responsable y sostenible',
              icon: null,
            },
            {
              label: 'Compromiso social',
              icon: null,
            },
            {
              label: 'Compromiso con las personas que integran la organización',
              icon: null,
            },
          ],
        },
        {
          label: 'Acerca de este informe',
          icon: null,
        },
        {
          label: 'Grupo de interés',
          icon: null,
          items: [
            {
              label: 'Empleados',
              icon: null,
            },
            {
              label: 'Proveedores',
              icon: null,
            },
            {
              label: 'Clientes',
              icon: null,
            },
            {
              label: 'Accionistas',
              icon: null,
            },
          ],
        },
      ],
    };

    // this.model['items'].unshift(apartadoEmpresa); // Agregar nuevo elemento al inicio
    this.model['items'].unshift(dashboard); // Agregar nuevo elemento al inicio

    this.model['items'].push(
      // {
      //   label: "Monitoring and supervision",
      //   icon: "pi pi-calendar",
      //   items: [
      //     {
      //       label: "Calendario de acciones",
      //       icon: null
      //     },
      //     {
      //       label: "Calendario de tareas",
      //       icon: null
      //     }
      //   ]
      // },
      {
        label: 'Reports',
        icon: 'pi pi-book',
        items: [
          {
            label: 'Panel',
            icon: 'pi pi-sliders-h',
            routerLink: ['/panel'],
          },
        ],
      },
      {
        label: 'Ajustes',
        icon: 'pi pi-cog',
        items: [
          {
            label: 'Menú',
            icon: null,
            items: [
              {
                label: 'Marcadores',
                icon: null,
                routerLink: ['form'],
              },
              {
                label: 'Indicadores',
                icon: null,
                routerLink: ['indicadores'],
              },
            ],
          },
        ],
      }
    );
  }

  /**
   * Recupera los datos del menú desde el servidor según un id de menú dado
   */
  recuperarDatosMenu() {
    // Obtengo id de menú a partir de id de empresa
    this.serverService.getData('/api/menus/' + this.companyId).subscribe({
      next: (response) => {
        if (response) {
          this.model = this.parsearRespuesta(response);
          this.agregarRouterLink(this.model);
          this.agregarElementosAdicionales(false);
        }
      },
      error: (err) => {
        if (!err.error.data) {
          this.agregarElementosAdicionales(true);
        }
      },
    });
  }
}
