import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../layout/service/app.layout.service';
import { ServerService } from '../server.service';
import { Indicator } from '../models/indicator';
import { Router } from '@angular/router';
import { concatMap, of } from 'rxjs';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.page.html',
  styleUrls: ['./panel.page.scss'],
})
export class PanelPage implements OnInit {
  public basicOptions: any;
  model: any[] = [];
  menuData: any;
  companyId: any;
  indicators: Indicator[] = [];
  filteredIndicators: any[] = [];
  itemFromUrl: any;
  data: any;
  icon: string | undefined;
  options: any;
  indicatorId: number = 0;
  loadingWord: boolean = false;
  dialogFilter: boolean = false;
  loadingModal: boolean = false;
  rangeDates: any[] = [];

  constructor(
    public layoutService: LayoutService,
    private serverService: ServerService,
    private router: Router
  ) {}

  ngOnInit() {
    this.companyId = sessionStorage.getItem('companyId');
    this.recuperarDatosMenu();
  }

  /**
   * Recupera los datos del menú desde el servidor según un id de menú dado
   */
  recuperarDatosMenu() {
    // Obtengo id de menú a partir de id de empresa
    this.serverService
      .getData('/api/menusWithIndicatorName/' + this.companyId)
      .subscribe({
        next: (response) => {
          if (response) {
            console.log(response);
            this.model = response.data;
          } else {
            console.log('No se encontraron datos');
          }
        },
        error: (err) => {
          if (!err.error.data) {
            console.log(err.error.data);
          } else {
            console.log('Error al obtener datos del menú');
          }
        },
      });
  }

  redirect(indicator) {
    if (indicator.isValidated === 'No validado' || indicator.isValidated === 'Validado') {
      this.router.navigate([
        'base-qualitative/' + indicator.name + '/' + indicator.id,
      ]);
    } else if (indicator.isEvaluated === 'Si' || indicator.isEvaluated === 'No') {
      this.router.navigate([
        'base-indicator/' + indicator.name + '/' + indicator.id,
      ]);
    }
  }

  /**
   * Función para parsear la respuesta JSON que devuelve el servidor.
   * @param {Object} response - Respuesta devuelta por el servidor.
   * @returns {Array} - Array con los elementos del menú.
   */
  parsearRespuesta(response) {
    return JSON.parse(response.data.json_menu);
  }

  downloadXBRL(item) {
    console.log(item);
  }

  /**
   * Manejador de modal de filtro de indicadores y obtengo todos los indicadores
   */
  handleFilterModal() {
    this.loadingModal = true;
    this.serverService.getData('/api/indicators').subscribe({
      next: (response) => {
        if (response.data) {
          this.loadingModal = false;
          this.dialogFilter = true;
          this.filteredIndicators = response.data;
          console.log(this.filteredIndicators);
        }
      },
    });
  }

  /**
   * Descargar archivo Word de todos los indicadores del sistema
   */
  downloadIndicatorsWord() {
    this.loadingWord = true;
    if (this.rangeDates.length > 0) {
      this.rangeDates = [
        new Date(this.rangeDates[0]),
        new Date(this.rangeDates[1]),
      ];
      this.filterIndicatorsByDateRange();
    }

    let arrayIndicatorId: (number | undefined)[] = [],
      jsonIndicators: string;
    this.filteredIndicators.forEach((item) => {
      arrayIndicatorId.push(item.id);
    });
    jsonIndicators = JSON.stringify(arrayIndicatorId);

    if (jsonIndicators.length > 0) {
      this.serverService
        .sendData(
          `/api/getWordMultiIndicator/${jsonIndicators}`,
          jsonIndicators
        )
        .subscribe({
          next: (response) => {
            if (response) {
              this.loadingWord = false;
              this.dialogFilter = false;
              window.open(response[0], '_blank');
            }
          },
          error: (err) => {
            console.error('Error al guardar el indicador con Excel:', err);
          },
        });
    }
  }

  /**
   * Filtrado de indicadores por fecha de evidencias
   */
  filterIndicatorsByDateRange(): void {
    const [startDate, endDate] = this.rangeDates;

    this.filteredIndicators = this.filteredIndicators
      .map((indicator) => {
        // Filtramos las acciones que tienen evidencias en el rango de fechas
        const filteredActions = indicator.action
          .filter((action) => {
            // Filtramos las evidencias que están en el rango de fechas
            const filteredEvidences = action.evidences.filter((evidence) => {
              const evidenceDate = new Date(evidence.date);
              return evidenceDate >= startDate && evidenceDate <= endDate;
            });

            // Si hay evidencias en el rango de fechas, incluimos la acción con las evidencias filtradas
            if (filteredEvidences.length > 0) {
              return { ...action, evidences: filteredEvidences };
            }
            return false; // Excluimos las acciones que no tienen evidencias en el rango de fechas
          })
          .filter(Boolean); // Filtramos los elementos `false` del array de acciones

        // Si hay acciones con evidencias en el rango de fechas, incluimos el indicador con las acciones filtradas
        if (filteredActions.length > 0) {
          return { ...indicator, action: filteredActions };
        }
        return false; // Excluimos los indicadores que no tienen acciones con evidencias en el rango de fechas
      })
      .filter(Boolean); // Filtramos los elementos `false` del array de indicadores
  }
}
