import { Component, Input, OnInit, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ServerService } from '../../server.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-autoform-component',
  templateUrl: './autoform.component.html',
  styleUrls: ['./autoform.component.scss'],
})
export class AutoFormComponent implements OnInit {
  @Input({ required: true }) jsonData!: any;
  @Input({ required: true }) labels!: any;
  @Input({ required: true }) id: any;
  jsonResult: any = {};
  dataLoaded: boolean = false;
  nivel: number = 0;
  previousKey: string | null = null;
  inputValues: number[] = [];
  uploadedFile: any = null;

  @ViewChildren('operationButton') operationButtons!: QueryList<ElementRef>;

  constructor(private serverService: ServerService, private messageService: MessageService) { }

  ngOnInit() {
    this.getAutoFormData();
  }

  getAutoFormData() {
    const deepCopyData = JSON.parse(JSON.stringify(this.jsonData));
    this.jsonResult = deepCopyData;
    this.dataLoaded = true;
  }

  getKeys(data: any): string[] {
    return Object.keys(data);
  }

  increaseLevel() {
    return this.nivel++;
  }

  saveType(key: string, event: Event, index: number, suffix = ""): void {
    const target = event.target as HTMLInputElement;
    const value = target.value;

    // Llamar a la función recursiva para buscar y actualizar el valor en jsonData
    this.updateTypeInJsonData(this.jsonResult, key, value, index, suffix);
  }

  updateTypeInJsonData(obj: any, key: string, value: any, index: number, suffix = "") {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (typeof obj[prop] === 'object') {
          if (prop == key) {
            // Si la propiedad coincide con la clave y existe la propiedad 'tipo'
            if (!Array.isArray(obj[prop]['tipo'])) {
              obj[prop]['tipo'] = new Array(this.labels.index).fill(0);
            }
            obj[prop]['tipo'][index] = suffix ? `${value}_${suffix}` : value;
          } else {
            // Si es un objeto, continuar la búsqueda en este objeto recursivamente
            this.updateTypeInJsonData(obj[prop], key, value, index, suffix);
          }
        }
      }
    }
  }

  updateTypeInJsonDataOperations(obj: any, key: string, value: any) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (typeof obj[prop] === 'object') {
          if (prop == key) {
            obj[prop]['tipo'] = [];
            obj[prop]['tipo'].push(value); // Asigna el valor en la posición index del array
          } else {
            // Si es un objeto, continuar la búsqueda en este objeto recursivamente
            this.updateTypeInJsonDataOperations(obj[prop], key, value);
          }
        }
      }
    }
  }

  removeCampoSuffix(key: string): string {
    return key.replace(/_campo\d+/g, '');
  }

  typeHasSuffix(data) {
    const parts = data.split('_');
    const coletilla = parts[1];

    return coletilla !== undefined ? coletilla : '';
  }

  deleteSuffix(data) {
    const parts = data.split('_');
    const type = parts[0];

    return type;
  }

  sendData() {
    this.operationButtons.forEach(button => {
      button.nativeElement.click();
    });
    //añadir labels para el resultado final (se quitan para poder procesar correctamente el json)
    this.jsonResult.labels = this.labels;

    const fileBlob = new Blob([this.uploadedFile], { type: this.uploadedFile.type });

    const formData = new FormData();
    formData.append('id_indicator', this.id);
    formData.append('id_responsible', 'example_responsible_id');
    formData.append('id_department', 'example_department_id');
    formData.append('description', 'Example description');
    formData.append('request', 'Example request');
    formData.append('data', JSON.stringify(this.jsonResult));
    formData.append('date', '2024-03-25');
    formData.append('observations', 'Example observations');
    formData.append('document_hash', 'example_hash');
    formData.append('document', fileBlob);

    this.serverService.sendData('/api/save-initial-state', formData).subscribe(
      response => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Data saved successfully' });
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to save data' });
      }
    );
  }


  extractNumber(str: string): number {
    const match = str.match(/\d+/)?.[0];
    return parseInt(match || '0', 10);
  }


  extractOperation(str: string): string {
    const match = str.match(/^[^(]+/)?.[0] || '';
    return match.trim();
  }

  obtenerTipo(json, campo) {
    for (const prop in json) {
      if (json.hasOwnProperty(prop)) {
        if (typeof json[prop] === 'object') {
          if (prop == campo) {
            return json[prop].tipo;
          } else {
            // Si es un objeto, continuar la búsqueda en este objeto recursivamente
            const resultado = this.obtenerTipo(json[prop], campo);
            if (resultado !== undefined) {
              return resultado;
            }
          }
        }
      }
    }
  }


  extraerValoresDesdeOperador(cadena, operador, campo) {
    const regex = /\(([^)]+)\)/;
    const match = cadena.match(regex);
    delete this.jsonResult.labels;

    if (!match) {
      return null; // No se encontró el formato esperado en la cadena
    }

    const variables = match[1].split(',').map(variable => variable.trim());
    const operacion = this.traducirOperador(operador);
    let valores: number[] = [];

    for (let variable of variables) {
      //obtener los arrays con valores para poder hacer las operaciones, juntar cada parte del array haciendo una suma
      let tipoConDatos = this.obtenerTipo(this.jsonResult, variable);

      if (tipoConDatos) {
        let sumaTotal = tipoConDatos.reduce((acumulador, valor) => {
          return acumulador + parseFloat(valor.split('_')[0]);
        }, 0);
        valores.push(sumaTotal);
      }
    }

    //realizar la operacion con las dos columnas seleccionadas
    let resultadoFinal: number = 0;
    if (operacion && valores.length > 1) {
      const primerValor = valores[0];
      const segundoValor = valores[1];
      switch (operacion) {
        case '+':
          resultadoFinal = primerValor + segundoValor;
          break;
        case '-':
          resultadoFinal = primerValor - segundoValor;
          break;
        case '*':
          resultadoFinal = primerValor * segundoValor;
          break;
        case '/':
          resultadoFinal = segundoValor !== 0 ? primerValor / segundoValor : 0;
          break;
        default:
          break;
      }
      //en caso de NaN en alguna de las cadenas de valores, solamente muestra el !NaN
      if (isNaN(resultadoFinal)) {
        resultadoFinal = operacion === '+' || operacion === '*' ? primerValor : segundoValor;
      }
    }

    if (resultadoFinal !== null) {
      //añadir el resultado sobre la key indicada 
      this.updateTypeInJsonDataOperations(this.jsonResult, campo, resultadoFinal);
    }

    return resultadoFinal;
  }


  traducirOperador(operador) {
    switch (operador) {
      case 'SUMA':
        return '+';
      case 'RESTA':
        return '-';
      case 'MULTIPLICACION':
        return '*';
      case 'DIVISION':
        return '/';
      default:
        return null; // Operador no reconocido
    }
  }


  onFileSelect(event: any): void {
    const selectedFiles = event.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      this.uploadedFile = selectedFile;
    }
  }


}
