import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ServerService {
  public apiUrl = environment.esrsApiURL;

  constructor(private http: HttpClient) {}

  // Método para obtener datos del servidor
  getData(relativeUrl: string): Observable<any> {
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
    });
    const url = this.apiUrl + relativeUrl;
    return this.http.get(url, { headers });
  }

  // Método para enviar datos al servidor
  sendData(relativeUrl: string, data: any): Observable<any> {
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
    });
    const url = this.apiUrl + relativeUrl;
    return this.http.post<any>(url, data, { headers }).pipe(
      tap((response) => {}),
      catchError((error: HttpErrorResponse) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Método para actualizar datos al servidor
   */
  updateData(relativeUrl: string, data: any) {
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
    });
    const url = this.apiUrl + relativeUrl;
    return this.http.put<any>(url, data, { headers }).pipe(
      tap((response) => {}),
      catchError((error: HttpErrorResponse) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
}
