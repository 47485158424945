import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { ServerService } from './server.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
class TokenGuard {
  constructor(
    private router: Router,
    private http: HttpClient,
    private serverService: ServerService
  ) {
    this.tokenFromUrl = '';
  }

  tokenFromUrl: string | null;
  companyId: string | null;

  //validar token
  private validateAndRedirect(token: string): Observable<boolean> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http
      .get<boolean>(this.serverService.apiUrl + `/api/check-token`, { headers })
      .pipe(
        tap((response) => {
          if (response == true) {
            sessionStorage.setItem('token', token);
          } else {
            sessionStorage.removeItem('token');
            this.router.navigate([environment.usersURL + '/login']);
          }
        }),
        map((response) => {
          if (response == true) {
            if (this.tokenFromUrl != '') {
              const currentUrl = new URL(window.location.href);
              currentUrl.searchParams.delete('token');
              currentUrl.searchParams.delete('companyId');
              const urlWithoutToken = currentUrl.toString();
              history.replaceState({}, document.title, urlWithoutToken);
              this.router.navigate(['/dashboard']);
            }
            return true;
          } else {
            return false;
          }
        }),
        catchError((error) => {
          console.error('Error al verificar el token:', error);
          sessionStorage.removeItem('token');
          this.router.navigate([environment.usersURL + '/login']);
          return of(false);
        })
      );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.tokenFromUrl = route.queryParamMap.get('token');
    this.companyId = route.queryParamMap.get('companyId');
    if (this.companyId) {
      sessionStorage.setItem('companyId', this.companyId);
    }

    if (this.tokenFromUrl) {
      return this.validateAndRedirect(this.tokenFromUrl);
    } else {
      const storedToken = sessionStorage.getItem('token');
      if (storedToken !== null && storedToken !== undefined) {
        this.tokenFromUrl = '';
        return this.validateAndRedirect(storedToken);
      } else {
        window.location.href = environment.usersURL + '/login';
        return of(false);
      }
    }
  }
}

export const isTokenGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  return inject(TokenGuard).canActivate(route, state);
};
