<ul class="layout-menu">
  <li
    app-menuitem
    *ngFor="let item of model['items']; let i = index"
    [item]="item"
    [index]="i"
    [root]="true"
    [ngClass]="test"
  ></li>
</ul>
