import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServerService } from '../server.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-autoform-edit',
  templateUrl: './autoform-edit.page.html',
  styleUrls: ['./autoform-edit.page.scss'],
  providers: [MessageService],
})
export class AutoformEditPage implements OnInit {

  // Propiedades para almacenar datos y controlar la interfaz
  id: string = ''; // Almacena el ID del objeto que se visualiza
  jsonData: any = {}; // Almacena los datos JSON recuperados del servidor
  labels: any = []; // Almacena las etiquetas de los datos JSON
  modal: boolean = false; // Controla la visibilidad de un modal en la interfaz

  constructor(
    private route: ActivatedRoute, // Para obtener parámetros de ruta
    private serverService: ServerService, // Servicio para hacer solicitudes HTTP al servidor
    private messageService: MessageService // Servicio para mostrar mensajes en la interfaz
  ) { }

  ngOnInit() {
    // Al inicializar el componente
    this.route.params.subscribe(params => {
      // Se suscribe a los cambios en los parámetros de ruta
      this.id = params['id']; // Obtiene el ID del objeto de la URL
      this.getJsonData(); // Llama a la función para obtener los datos JSON
    });
  }

  // Función para obtener los datos JSON del servidor
  getJsonData() {
    this.serverService.getData(`/api/get-config/${this.id}`).subscribe(data => {
      // Hace una solicitud HTTP GET al servidor para obtener los datos JSON
      this.jsonData = JSON.parse(data.config); // Almacena los datos JSON recuperados
      this.labels = this.jsonData.labels; // Extrae y almacena las etiquetas de los datos JSON
      delete this.jsonData.labels; // Elimina las etiquetas de los datos JSON
    });
  }

  // Función para verificar si los datos JSON están vacíos
  isJsonDataEmpty(): boolean {
    return Object.keys(this.jsonData).length === 0; // Retorna true si los datos están vacíos, de lo contrario false
  }
  openModal() {
    this.modal = true;
  }

}
